subject {
    color: #37d1b0;
}

* {
    -ms-overflow-style: none;
}
/* ::-webkit-scrollbar {
    display: none;
} */
