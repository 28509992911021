body {
    min-width: fit-content;
    min-height: fit-content;
}

#root,
body {
    height: 100vh;
    height: -webkit-fill-available;
}

p,
button,
input {
    font-family: "Raleway", sans-serif;
}
/*
.buttonAnimation:after {
  content: "";
  background: #7a7e7a;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -100px!important;
  margin-top: -25%!important;
  opacity: 0;
  transition: all 1.0s
}

.buttonAnimation:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}
*/

.App {
    text-align: center;
}

/* Native forms styling */
.nf-question-header {
    text-align: left !important;
    line-height: 22px !important;
    display: flex;
    align-items: center;
}

.nf-question-number {
    margin-right: 5px;
}

.nf-toasts-wrapper {
    position: fixed !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.test {
    color: red;
}
